/* eslint-disable react/prop-types */
import React from 'react'
import Zoom from 'react-reveal/Zoom'
import { Link } from 'gatsby'

const TrainingCard = ({data}) => {
    return (
        <>
            {data.map((data,i)=>{
                return(
                    <Zoom right key={i}>
                        <div className="border-gray-200 border-2 m-3 rounded-md shadow-md">
                            <div className="flex justify-between">
                                <p className="text-mainColor mt-2 text-xl font-bold text-left pl-2 ">{data.title}</p>
                                {data.icon ? <img className="m-1" src={data.icon} style={{width:'48px', height:'48px'}}/> : null}
                            </div>
                            <p className="text-left text-mainColor font-semibold border-b-2 pl-2 border-yellowColor">Duration: {data.duration}</p>
                            <p className="text-left text-mainColor md:h-52 lg:h-auto pl-2">{data.description}</p>
                            <div className="flex justify-center pl-2">
                                <button className="mb-3">
                                    <Link to='/trainingForm' className="bg-mainColor text-white rounded-md no-underline p-2 ">Ask for Details</Link>
                                </button>
                            </div>
                        </div>
                    </Zoom>
                )
            })}
        </>
    )
}
export default TrainingCard