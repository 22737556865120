import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import Fade from 'react-reveal'
import icon1 from '../../images/streamline-icon-study-owl@48x48.svg'
import icon2 from '../../images/streamline-icon-single-man-actions-star@48x48.svg'
import icon3 from '../../images/streamline-icon-earth-locate@48x48.svg'
const TrainingIntroduction = () => {
    return (
        <div className="py-5 px-4 bg-bgGray">
            <div className="max-w-screen-xl w-4/5 md:w-full m-auto">
                <p className="text-lightBlue2 sm:text-xl font-bold">Grow your knowledge and your career opportunities with our carefully and thoughtfully  curated trainings.</p>
                <Fade left>
                    <div className="grid md:grid-cols-3">
                        <div className="border shadow-md rounded-md m-4 bg-white">
                            <img className="m-auto mt-4 mb-2" src={icon1} style={{width:'56px', height:'56px'}}/>
                            <p className=" text-mainColor font-bold sm:text-xl text-lg py-2 rounded-t-md text-center">Learn New Top Skills</p>
                            <div className="text-left w-5/6 m-auto">
                                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>Acquiring new skills will give you more career options </p>
                                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>Get in-depth know-how on newest international topics and trends </p>
                            </div>
                        </div>
                        <div className="border shadow-md rounded-md m-4 bg-white">
                            <img className="m-auto mt-4 mb-2" src={icon2} style={{width:'56px', height:'56px'}}/>
                            <p className="sm:text-xl text-lg text-mainColor font-bold py-2 rounded-t-md text-center">Learn From Industry Experts</p>
                            <div className="text-left w-5/6 m-auto">
                                <p className="font-semibold text-mainColor flex "><CheckIcon className="text-yellowColor mr-1"/>Experienced teachers will speed up the learning process with their best approaches </p>
                                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>Skip the small talk and discuss the details of the chosen topics </p>
                            </div>
                        </div>
                        <div className="border shadow-md rounded-md m-4 bg-white">
                            <img className="m-auto mt-4 mb-2" src={icon3} style={{width:'56px', height:'56px'}}/>
                            <p className="sm:text-xl text-lg text-mainColor font-bold py-2 rounded-t-md text-center">Learn From Anywhere</p>
                            <div className="text-left w-5/6 m-auto">
                                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>The learning process should fit your schedule </p>
                                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>We give you options to enjoy learning from anywhere in the world </p>
                            </div></div>
                    </div>
                </Fade>
            </div>
        </div>
    )

}
export default TrainingIntroduction